
<script lang="jsx">
// utils
import { columnToWidth, views } from './js/constant'

// mixins
import productCardMixin from './mixins/productCardMixin'

export default {
  name: 'MultipleImageRowCard',
  components: {},
  mixins: [productCardMixin],
  props: {
    // 是否付费会员
    isPaid: {
      type: Boolean,
      default: false
    },
    // 自定义config 与商卡配置使用一致
    customConfig: {
      type: Object,
      default: () => ({})
    },
    // 一行几图
    column: {
      type: [Number, String],
      default: 3
    },
    // 组件来源
    compSrc: {
      type: String,
      default: ''
    },
    compImgDesignWidth: {
      type: Number,
      default: 0
    }
  },
  data(){
    return {
      labelsFromKey: views.LIST_MOBILE_VERTICAL_VIEW,

      defaultConfig: {
        showLocateLabels: true, // 主图角标
        showSeriesBrand: true, // 品牌系列
        showMultiColor: false,            // 色块
        showMultiColorIcon: false,        // 色块
        showAddBagBtnBottomRight: false,  // 加车在主图右下角

        showMultiColumnSuggestedSalePrice: false,   // 多行商卡显示德国合规价
        deEquallyDisplayTheSuggestedSalePrice: false, // 德国合规，30天最低价与原价相等，不展示icon，展示30天最低价（列表逻辑是隐藏30天最低价展示icon tip）

        // 价格
        showCamelPrice: true,   // 驼峰价格
        dynamicCamelPrice: false,      // 动态驼峰价格，默认非驼峰 长度超出改为驼峰
        showNewFlashPrice: true,    // 新型闪购价
        showNewFlashPriceOnSale: false,   // 新型闪购价
        showEstimatedPriceOnSale: true,     // 到手价
        showSheinClubPriceOnSale: true,   // 会员价
      },

      // 是否使用驼峰价格
      showCamelPrice: false,
      priceTransparent: false
    }
  },
  computed: {
    // 自定义与传入合成最终config
    config() {
      const { IS_RW } = this.constantData || {}
      const finalConfig = Object.assign({ ...this.defaultConfig }, {
        showSheinClubPriceOnSale: !!this.isPaid,    // 付费会员价
        showNewFlashPriceOnSale: !IS_RW,      // 新型闪购价
      },  this.customConfig)

      return finalConfig
    }
  },
  created() {
    if (this.config.dynamicCamelPrice && this.config.showCamelPrice) {
      this.priceTransparent = true
      this.showCamelPrice = true
    } else {
      this.showCamelPrice = this.config.showCamelPrice
    }
  },
  mounted() {
    if (this.config.dynamicCamelPrice && this.config.showCamelPrice) {
      this.handleDynamicCamelPrice()
    }
  },
  methods: {
    // 计算价格宽度，不够则显示驼峰价格
    handleDynamicCamelPrice() {
      const productCardBottomWrapperRef = this.$refs.productCardBottomWrapperRef ?? {}

      const priceWrapDom = productCardBottomWrapperRef.querySelector?.('.prices-info__content')

      const priceContentDom = productCardBottomWrapperRef.querySelector?.('.content__sale-price-wrapper')
      if (!priceWrapDom || !priceContentDom) {
        this.priceTransparent = false
        return false
      }

      const { width: priceWrapDomWidth } = priceWrapDom.getBoundingClientRect?.() || {}
      const { width: priceContentDomWidth } = priceContentDom.getBoundingClientRect?.() || {}
      if (priceWrapDomWidth < priceContentDomWidth) {
        this.showCamelPrice = false
      }

      this.priceTransparent = false
    }
  },
  render(h) {
    // 简易价格组件，只保留完整主图&腰带&角标&价格功能，供给三图及以上场景使用
    // 主图负反馈等弹窗也已移除

    // config
    const { hideSoldOut, autoCropImage, showAddBagBtnBottomRight, hideAllBottomPrices } = this.config || {}

    const { 
      itemAdaOptions,
      mainImg,
      isSoldOut,
      comingSoon,
      showBestDeal,
      followLabelVisible,
      beltLabel,
      exclusivePromotionPrice,
      cornerPropertyUpsell,
      ItemLocateLabels,
      ProductCardImgLeftTop,
      ProductCardImgRightTop,
      ProductCardImgLeftBottom,
    } = this.handleRender(h)

    return (
      <div
        v-show={this.status.showSelf}
        ref="productCardRef"
        class="product-card simple-row-item"
        style={{ 
          '--column-num': `${columnToWidth[this.column]}rem`,
          '--product-card-radius': 0
        }}
      >
        {/* 主图区域 */}
        <div
          class="product-card__top-wrapper"
          {...itemAdaOptions}
          vOn:touchstart={this.touchstart}
          vOn:touchmove={this.touchmove}
          vOn:touchend={this.touchend}
          vOn:contextmenu_prevent={() => {}}
          vOn:click_prevent={(e) => this.clickItem(e, { from: 'mainImg' } )}
        >
          {/* 主图区域 */}
          <div class="top-wrapper__main-img">
            {/* 主图区域 */}
            <ProductCardImg 
              mainImg={mainImg} 
              column={this.column} 
              vOn:imgLoadFinish={this.onImgLoadFinish}
              index={this.index} 
              autoCropImage={autoCropImage} 
              cropRate={this.cropRate}
              lcp={this.lcp}
              reportMetrics={this.reportMetrics}
              compSrc={this.compSrc}
              compImgDesignWidth={this.compImgDesignWidth}
            />
            <ClientOnly>
              {/* 定位角标 */}
              { ItemLocateLabels() }
            </ClientOnly>
          </div>

          {/* 主图下方区域 */}
          <div class="top-wrapper__bottom">
            <div class="top-wrapper__bottom-middletop">
              {/* 主图左下区域 */}
              <div class="bottom-middletop__top-left">
                <ClientOnly>
                  {ProductCardImgLeftBottom()}
                </ClientOnly>
              </div>

              {/* 撑开左右两个节点 */}
              {
                <div class="bottom-middletop__top-middle"></div>
              }

              {/* 主图右下区域 */}
              <div class="bottom-middletop__top-right">
                {
                  showAddBagBtnBottomRight ?
                    (
                      <ProductItemAddToBag
                        box-shadow={false}
                        size="small"
                        custom-style={this.addToBagStyle}
                        vOn:openQuickAdd={this.handleOpenQuickAdd} 
                        is-rw={this.IS_RW}
                      />
                    )
                    : (
                      <ProductCardImgRightBottom
                        beltLabel={beltLabel}
                        getCornerPropertyUpsell={cornerPropertyUpsell}
                        relatedColor={this.relatedColor}
                      />
                    )
                }
              </div>
            </div>

            {/* 主图下方中位区域 */}
            <div class="top-wrapper__bottom-middle">
              <ClientOnly>
                <ProductCardImgBottom
                  coming-soon={comingSoon}
                  is-sold-out={isSoldOut}
                  show-best-deal={showBestDeal}
                  follow-label-visible={followLabelVisible}
                  belt-label={beltLabel}
                  save-amount={this.saveAmount}
                >
                  {/* 图片底部slot(嵌套) */}
                  <template slot="imgBottom">{this.$slots.imgBottom}</template>
                  {/* 图片底部slot(盖在腰带之上) */}
                  <template slot="imgBottomInTop">{this.$slots.imgBottomInTop}</template>
                </ProductCardImgBottom>
              </ClientOnly>
            </div>
          </div>

          {/* 主图左上区域 */}
          <div class="top-wrapper__top-left">
            <ClientOnly>
              {ProductCardImgLeftTop()}
              { this.$slots.imgTopLeft }
            </ClientOnly>
          </div>

          {/* 主图右上区域 */}
          <div class="top-wrapper__top-right">
            <ClientOnly>
              {ProductCardImgRightTop()}
            </ClientOnly>
          </div>

          {/* 售罄背景slot，默认展示Sold Out */}
          {
            this.$slots.soldout  || (
              !hideSoldOut && isSoldOut && !comingSoon && !this.soldoutOperationPanelShow
                ? (
                  <ProductItemSoldout
                    cur-data={this.curData}
                    language={this.language}
                    constant-data={this.constantData}
                    config={this.config}
                    is-sold-out={isSoldOut}
                    showSimilarPopup={this.showSimilarPopup}
                    index={this.index}
                    show-select={this.showSelect}
                    vOn:clickAddToBoard={(payload) => this.$emit('clickAddToBoard', payload)}
                    vOn:clickDelete={(payload) => this.$emit('clickDelete', payload)}
                    vOn:select={this.handleSelect}
                    vOn:clickItem={() => this.clickItem(null, { from: 'soldOut' } )}
                  />
                )
                : null
            )
          }
        </div>


        {/* 主要信息区域（价格，标题，标签等）*/}
        {
          !hideAllBottomPrices ? (
            <div 
              ref="productCardBottomWrapperRef"
              class={[ 
                'product-card__bottom-wrapper',
                {
                  'product-card__bottom-wrapper_soldout': isSoldOut,
                  'transparent': this.priceTransparent,
                  'dynamic-price': this.config.dynamicCamelPrice
                } 
              ]}
              vOn:click_prevent={(e) => this.clickItem(e, { from: 'cardInfo' } )}
            >
              {/* 价格 */}
              {
                this.$slots.price || (
                  <ProductCardPrices
                    class="price-wrapper__price"
                    show-camel-price={this.showCamelPrice}
                    exclusive-promotion-price={exclusivePromotionPrice}
                    show-best-deal={showBestDeal}
                    belt-label={beltLabel}
                    support-vue-debug={this.supportVueDebug}
                    multi-column
                  />
                )
              }
            </div>  
          ) : null
        }
      </div>
    )
  }
}
</script>


<style lang="less">
@import url(./style/checkBox.less);
@import url('./style/icon.less');

.simple-row-item {
  width: var(--column-num);
  background: var(--product-card-bg, #fff);  // 实时反馈用
  background-image: var(--product-card-bg-image, none);

  position: relative;
  
  // 主图区域
  .product-card__top-wrapper {
    position: relative;
    overflow: hidden;

    border-radius: var(--product-card-img-radius, 0);

    .top-wrapper {
      // &__main-img {
      //   overflow: hidden;
      // }
      // 主图下方区域
      &__bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      // 主图下方中位区域上
      &__bottom-middletop {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
      // 主图左上区域
      &__top-left {
        position: absolute;
        left: 0;
        top: 0;
        max-width: 100%;
      }
      // 主图右上区域
      &__top-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  // 主要信息
  .product-card__bottom-wrapper {
    position: relative;
    padding: var(--product-card__bottom-wrapper, .106rem 0 0 0); // 实时反馈用

    &.transparent {
      opacity: 0;
    }

    &.dynamic-price .content__sale-price-wrapper {
      height: 0.5333rem;
      line-height: 0.5333rem;
    }
    
    &_soldout > div, &_soldout > section {
      opacity: 0.3;
    }
    .product-card__camel-case-price__number-before-dot {
      font-size: .3733rem;
    }
    
  }
}
</style>
